* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #6639E6;
  --primary-10-color: #6639E61A;
  --purple-light-color: #E5E1FC;
  --black-light-color: #A9A7AF;
  --black-10-color: #0202021A;
  --black-50-color: #02020280;
  --grey-light-color: #F3F2F8;
  --grey-color: #E6E7EB;
  --black-color: #020202;
  --gold-color: #FFB21D;
  --green-color: #4EB046;
  --green-10-color: #4EB0461A;
  --pink-color: #FF84D5;
  --pink-10-color: #FF84D51A;
  --red-color: #E53E3E;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@font-face {
  font-family: Avenir300;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrlight.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrlight.woff') format('woff');
}

@font-face {
  font-family: Avenir400;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrregular.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrregular.woff') format('woff');
}

@font-face {
  font-family: Avenir500;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrmedium.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrmedium.woff') format('woff');
}

@font-face {
  font-family: Avenir600;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrdemi.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrdemi.woff') format('woff');
}

@font-face {
  font-family: Avenir700;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrbold.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrbold.woff') format('woff');
}

@font-face {
  font-family: Avenir800;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrheavy.woff2') format('woff2'),
    url('../src/assets/fonts/AvenirNextCyr/avenirnextcyrheavy.woff') format('woff');
}

.plan_fetching_loader {
  font-size: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
  filter: invert(1);
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
